import React from "react"
import './style.scss'
import WebpImage from "../WebpImage"

const ContactUs = () => {
  return (
    <div className="contact-us ">
      <div className=" top-section">
        <div className="top-section--background">
          <WebpImage className="desktop-image" wrapperClassName="desktop-image" fileName="Pages/contactUs/Contact-Us-Hero-Module-Desktop.jpg" />
          <WebpImage className="mobile-image" wrapperClassName="mobile-image" fileName="Pages/contactUs/Contact-Us-Hero-Module-Mobile.jpg" />
        </div>
        <div className="grid-section">
          <div className="copy-section">
            <h1>Contact Us</h1>
            <p>We're here to help</p>
          </div>
        </div>
      </div>
      <div className="action-section ">
        <div className="row">
          <div className="action-item col-md-4">
            <h2>Phone</h2>
              <p className="cta"><a href="tel:+1-844-334-1640">1-844-334-1640</a></p>
              <p className="subtext">
                <i>Give us a call</i>. Our customer service team is ready to help — 7 days a week from 9am-9pm Eastern Time.
            </p>
          </div>
          <div className="action-item col-md-4">
            <h2>Chat</h2>
            <p className="cta"><a href="javascript:zE('messenger', 'open');" >Open Chat</a></p>
            <p className="subtext"><i>Send us a message</i>. Start a conversation with our online team.</p>
          </div>
          <div className="action-item col-md-4">
            <h2>Questions?</h2>
            <p className="cta"><a href="/faq">Visit Our FAQs</a></p>
            <p className="subtext"><i>Find answers.</i> We’ve taken your most pressing questions and queued up answers we know will help.</p>
          </div>
        </div>
      </div>
      <div className="grid-section">
        <div className="contact-us-cta">
          <div className="row">
            <div className="col-md-4 text-section">
              <p className="small-bold">Contacts start at just $1</p>
              <p className="cta">Delivered right to your door! See, easy.</p>
              <a href={"/contact-lenses/hubble"}>Shop Contact Lenses <WebpImage fileName='assets/ContactUs/right-arrow-icon.svg' alt="" /></a>
            </div>
            <div className="col-md-8 image-holder">
              <div className="desktop-only">
                <WebpImage fileName='Pages/contactUs/Contact-Us-Shop-Module-Desktop.jpg' alt="colorful boxes of contact lenses" />
              </div>
              <div className="mobile-only">
                <WebpImage fileName='Pages/contactUs/Contact-Us-Shop-Module-Mobile.jpg' alt="colorful boxes of contact lenses" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
